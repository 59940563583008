<template>
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="va-row">
				<div class="flex container-ddh-menu xs12 with-bottom va-row">
					<div class="flex xs12 no-padding">
						<h1>Cronograma</h1>
					</div>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>

		<div class="container-dhh va-row">
			<div class="flex md12 xs12 no-padding">
				<h4></h4>
			</div>
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding fiscal-notes">
							<div class="body-spacing">
								<div
									class="va-row voucher-disclaimer"
									v-if="managerCredential.settings.book_limit != 'false'"
								>
									<div v-if="managerCredential && managerCredential.settings">
										<div class="booklimit-value">
											<b>{{
												bookLimit
											}}</b>
											vouchers validados<br />
											<b>Módulo contratado:</b> Plataforma de personalização
											online + Livro personalizado físico

											<!-- <span
												>{{
													managerCredential.settings.book_limit
														.toString()
														.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
												}}
												Vouchers restantes</span -->
										</div>
									</div>
								</div>

								<div class="va-row">
									<div class="flex xs12 no-padding">
										<div class="sets-list va-row">
											<div class="va-row">
												<div class="flex md3 xs12 no-padding-left">
													<div class="input-group">
														<label>Status</label>
														<select-normal
															:items="listStatus"
															:value="''"
															label="Selecione um status"
															name="typeName"
															v-model="selectStatus"
															@change="onChangeFilterStatus($event)"
														/>
													</div>
												</div>

												<div class="flex md3 xs12">
													<div class="input-group">
														<label>Escolas</label>
														<select-normal
															:items="listSchool"
															:value="''"
															label="Selecione uma escola"
															name="typeName"
															v-model="selectSchool"
															@change="onChangeFilterSchools($event)"
														/>
													</div>
												</div>

												<div class="flex md3 xs12">
													<label>Faturamento</label>
													<div class="input-group">
														<select-normal
															:items="listPaymentStatus"
															:value="''"
															label="Selecione um status"
															name="typeName"
															v-model="selectPaymentStatus"
															@change="onChangeFilterPaymentStatus($event)"
														/>
													</div>
												</div>

												<div class="flex md3 xs12 no-padding-right">
													<label>Tipo de contrato</label>
													<div class="input-group">
														<select-normal
															:items="listContracts"
															:value="''"
															label="Selecione um contrato"
															name="typeName"
															v-model="selectContracts"
															@change="onChangeFilterContracts($event)"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<!-- selectContracts:"1"
selectPaymentStatus:""
selectPayments:""
selectSchool:"203"
selectStatus:"20" -->

									<div class="flex xs12 no-padding">
										<div
											class="va-row empty-area"
											v-if="
												list.length == 0 &&
												selectContracts &&
												selectSchool &&
												selectStatus
											"
										>
											<div class="empty-box">
												<div class="empty-box-header">ADOÇÕES</div>
												<div class="empty-box-body">
													<b>Nenhuma adoção ativa no momento</b><br />
													O cronograma estará disponível assim que as adoções
													estiverem ativas.
												</div>
											</div>
										</div>

										<div
											class="va-row empty-area"
											v-if="
												list.length == 0 &&
												(!selectContracts || !selectSchool || !selectStatus)
											"
										>
											<div class="empty-box">
												<div class="empty-box-header">ADOÇÕES</div>
												<div class="empty-box-body">
													<b
														>Não encontramos nenhum resultado, para o filtro
														selecionado</b
													><br />
													Filtre novamente ou limpe os filtros clicando no botão
													abaixo.
													<br />
												</div>
											</div>
										</div>
									</div>

									<div class="sets-list table-list" v-if="list.length > 0">
										<div class="va-row header no-padding">
											<div class="flex item main">ADOÇÃO</div>

											<!-- <div class="flex item book-quantity">
												Qtd. de livros

												<span
													content="O número da esquerda representa a quantidade de livros criados nessa adoção. O número da direita representa o quantidade de alunos cadastrados."
													v-tippy="{
														interactive: true,
														arrow: true,
													}"
												>
													<svg
														width="12"
														height="12"
														viewBox="0 0 12 12"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M4.0625 10.6062C4.67083 10.8687 5.31667 11 6 11C6.69167 11 7.34167 10.8687 7.95 10.6062C8.55833 10.3437 9.0875 9.98542 9.5375 9.53125C9.9875 9.07708 10.3437 8.54583 10.6062 7.9375C10.8687 7.32917 11 6.68333 11 6C11 5.30833 10.8687 4.65833 10.6062 4.05C10.3437 3.44167 9.9875 2.9125 9.5375 2.4625C9.0875 2.0125 8.55833 1.65625 7.95 1.39375C7.34167 1.13125 6.69583 1 6.0125 1C5.32083 1 4.67083 1.13125 4.0625 1.39375C3.45417 1.65625 2.92292 2.0125 2.46875 2.4625C2.01458 2.9125 1.65625 3.44167 1.39375 4.05C1.13125 4.65833 1 5.30417 1 5.9875C1 6.67917 1.13125 7.32917 1.39375 7.9375C1.65625 8.54583 2.01458 9.07708 2.46875 9.53125C2.92292 9.98542 3.45417 10.3437 4.0625 10.6062ZM6 9C5.68934 9 5.4375 8.74816 5.4375 8.4375V6.0625C5.4375 5.75184 5.68934 5.5 6 5.5C6.31066 5.5 6.5625 5.75184 6.5625 6.0625V8.4375C6.5625 8.74816 6.31066 9 6 9ZM6 4.5C6.21212 4.5 6.39015 4.43182 6.53409 4.29545C6.67803 4.15909 6.75 3.98485 6.75 3.77273C6.75 3.5606 6.67803 3.37879 6.53409 3.22727C6.39015 3.07576 6.21212 3 6 3C5.78788 3 5.60985 3.07576 5.46591 3.22727C5.32197 3.37879 5.25 3.5606 5.25 3.77273C5.25 3.98485 5.32197 4.15909 5.46591 4.29545C5.60985 4.43182 5.78788 4.5 6 4.5Z"
															fill="#68596E"
														/>
													</svg>
												</span>
											</div> -->
											<div
												class="flex item dates"
												v-if="!managerCredential.settings.hide_payment_data"
											>
												Pagamento

												<span
													content="Vencimento do pagamento para liberação das próximas etapas"
													v-tippy="{
														interactive: true,
														arrow: true,
													}"
												>
													<svg
														width="12"
														height="12"
														viewBox="0 0 12 12"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M4.0625 10.6062C4.67083 10.8687 5.31667 11 6 11C6.69167 11 7.34167 10.8687 7.95 10.6062C8.55833 10.3437 9.0875 9.98542 9.5375 9.53125C9.9875 9.07708 10.3437 8.54583 10.6062 7.9375C10.8687 7.32917 11 6.68333 11 6C11 5.30833 10.8687 4.65833 10.6062 4.05C10.3437 3.44167 9.9875 2.9125 9.5375 2.4625C9.0875 2.0125 8.55833 1.65625 7.95 1.39375C7.34167 1.13125 6.69583 1 6.0125 1C5.32083 1 4.67083 1.13125 4.0625 1.39375C3.45417 1.65625 2.92292 2.0125 2.46875 2.4625C2.01458 2.9125 1.65625 3.44167 1.39375 4.05C1.13125 4.65833 1 5.30417 1 5.9875C1 6.67917 1.13125 7.32917 1.39375 7.9375C1.65625 8.54583 2.01458 9.07708 2.46875 9.53125C2.92292 9.98542 3.45417 10.3437 4.0625 10.6062ZM6 9C5.68934 9 5.4375 8.74816 5.4375 8.4375V6.0625C5.4375 5.75184 5.68934 5.5 6 5.5C6.31066 5.5 6.5625 5.75184 6.5625 6.0625V8.4375C6.5625 8.74816 6.31066 9 6 9ZM6 4.5C6.21212 4.5 6.39015 4.43182 6.53409 4.29545C6.67803 4.15909 6.75 3.98485 6.75 3.77273C6.75 3.5606 6.67803 3.37879 6.53409 3.22727C6.39015 3.07576 6.21212 3 6 3C5.78788 3 5.60985 3.07576 5.46591 3.22727C5.32197 3.37879 5.25 3.5606 5.25 3.77273C5.25 3.98485 5.32197 4.15909 5.46591 4.29545C5.60985 4.43182 5.78788 4.5 6 4.5Z"
															fill="#68596E"
														/>
													</svg>
												</span>
											</div>
											<div class="flex item dates">
												Aprovação
												<span
													content="Data limite para os professores liberarem os livros para a impressão. <b>Atenção</b>: O atraso nessa data pode impactar a entrega do Evento."
													v-tippy="{
														interactive: true,
														arrow: true,
													}"
												>
													<svg
														width="12"
														height="12"
														viewBox="0 0 12 12"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M4.0625 10.6062C4.67083 10.8687 5.31667 11 6 11C6.69167 11 7.34167 10.8687 7.95 10.6062C8.55833 10.3437 9.0875 9.98542 9.5375 9.53125C9.9875 9.07708 10.3437 8.54583 10.6062 7.9375C10.8687 7.32917 11 6.68333 11 6C11 5.30833 10.8687 4.65833 10.6062 4.05C10.3437 3.44167 9.9875 2.9125 9.5375 2.4625C9.0875 2.0125 8.55833 1.65625 7.95 1.39375C7.34167 1.13125 6.69583 1 6.0125 1C5.32083 1 4.67083 1.13125 4.0625 1.39375C3.45417 1.65625 2.92292 2.0125 2.46875 2.4625C2.01458 2.9125 1.65625 3.44167 1.39375 4.05C1.13125 4.65833 1 5.30417 1 5.9875C1 6.67917 1.13125 7.32917 1.39375 7.9375C1.65625 8.54583 2.01458 9.07708 2.46875 9.53125C2.92292 9.98542 3.45417 10.3437 4.0625 10.6062ZM6 9C5.68934 9 5.4375 8.74816 5.4375 8.4375V6.0625C5.4375 5.75184 5.68934 5.5 6 5.5C6.31066 5.5 6.5625 5.75184 6.5625 6.0625V8.4375C6.5625 8.74816 6.31066 9 6 9ZM6 4.5C6.21212 4.5 6.39015 4.43182 6.53409 4.29545C6.67803 4.15909 6.75 3.98485 6.75 3.77273C6.75 3.5606 6.67803 3.37879 6.53409 3.22727C6.39015 3.07576 6.21212 3 6 3C5.78788 3 5.60985 3.07576 5.46591 3.22727C5.32197 3.37879 5.25 3.5606 5.25 3.77273C5.25 3.98485 5.32197 4.15909 5.46591 4.29545C5.60985 4.43182 5.78788 4.5 6 4.5Z"
															fill="#68596E"
														/>
													</svg>
												</span>
											</div>
											<div class="flex item dates">
												Produção
												<span
													content="2 dias após a aprovação dos livros, os livros entram em produção.<br>
<b>Atenção</b>: O atraso nessa data pode impactar a entrega do Evento."
													v-tippy="{
														interactive: true,
														arrow: true,
													}"
												>
													<svg
														width="12"
														height="12"
														viewBox="0 0 12 12"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M4.0625 10.6062C4.67083 10.8687 5.31667 11 6 11C6.69167 11 7.34167 10.8687 7.95 10.6062C8.55833 10.3437 9.0875 9.98542 9.5375 9.53125C9.9875 9.07708 10.3437 8.54583 10.6062 7.9375C10.8687 7.32917 11 6.68333 11 6C11 5.30833 10.8687 4.65833 10.6062 4.05C10.3437 3.44167 9.9875 2.9125 9.5375 2.4625C9.0875 2.0125 8.55833 1.65625 7.95 1.39375C7.34167 1.13125 6.69583 1 6.0125 1C5.32083 1 4.67083 1.13125 4.0625 1.39375C3.45417 1.65625 2.92292 2.0125 2.46875 2.4625C2.01458 2.9125 1.65625 3.44167 1.39375 4.05C1.13125 4.65833 1 5.30417 1 5.9875C1 6.67917 1.13125 7.32917 1.39375 7.9375C1.65625 8.54583 2.01458 9.07708 2.46875 9.53125C2.92292 9.98542 3.45417 10.3437 4.0625 10.6062ZM6 9C5.68934 9 5.4375 8.74816 5.4375 8.4375V6.0625C5.4375 5.75184 5.68934 5.5 6 5.5C6.31066 5.5 6.5625 5.75184 6.5625 6.0625V8.4375C6.5625 8.74816 6.31066 9 6 9ZM6 4.5C6.21212 4.5 6.39015 4.43182 6.53409 4.29545C6.67803 4.15909 6.75 3.98485 6.75 3.77273C6.75 3.5606 6.67803 3.37879 6.53409 3.22727C6.39015 3.07576 6.21212 3 6 3C5.78788 3 5.60985 3.07576 5.46591 3.22727C5.32197 3.37879 5.25 3.5606 5.25 3.77273C5.25 3.98485 5.32197 4.15909 5.46591 4.29545C5.60985 4.43182 5.78788 4.5 6 4.5Z"
															fill="#68596E"
														/>
													</svg>
												</span>
											</div>
											<div class="flex item dates">
												Entrega
												<span
													content="Esta é a data prevista para os livros chegarem no endereço de entrega já combinados no momento da adoção."
													v-tippy="{
														interactive: true,
														arrow: true,
													}"
												>
													<svg
														width="12"
														height="12"
														viewBox="0 0 12 12"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M4.0625 10.6062C4.67083 10.8687 5.31667 11 6 11C6.69167 11 7.34167 10.8687 7.95 10.6062C8.55833 10.3437 9.0875 9.98542 9.5375 9.53125C9.9875 9.07708 10.3437 8.54583 10.6062 7.9375C10.8687 7.32917 11 6.68333 11 6C11 5.30833 10.8687 4.65833 10.6062 4.05C10.3437 3.44167 9.9875 2.9125 9.5375 2.4625C9.0875 2.0125 8.55833 1.65625 7.95 1.39375C7.34167 1.13125 6.69583 1 6.0125 1C5.32083 1 4.67083 1.13125 4.0625 1.39375C3.45417 1.65625 2.92292 2.0125 2.46875 2.4625C2.01458 2.9125 1.65625 3.44167 1.39375 4.05C1.13125 4.65833 1 5.30417 1 5.9875C1 6.67917 1.13125 7.32917 1.39375 7.9375C1.65625 8.54583 2.01458 9.07708 2.46875 9.53125C2.92292 9.98542 3.45417 10.3437 4.0625 10.6062ZM6 9C5.68934 9 5.4375 8.74816 5.4375 8.4375V6.0625C5.4375 5.75184 5.68934 5.5 6 5.5C6.31066 5.5 6.5625 5.75184 6.5625 6.0625V8.4375C6.5625 8.74816 6.31066 9 6 9ZM6 4.5C6.21212 4.5 6.39015 4.43182 6.53409 4.29545C6.67803 4.15909 6.75 3.98485 6.75 3.77273C6.75 3.5606 6.67803 3.37879 6.53409 3.22727C6.39015 3.07576 6.21212 3 6 3C5.78788 3 5.60985 3.07576 5.46591 3.22727C5.32197 3.37879 5.25 3.5606 5.25 3.77273C5.25 3.98485 5.32197 4.15909 5.46591 4.29545C5.60985 4.43182 5.78788 4.5 6 4.5Z"
															fill="#68596E"
														/>
													</svg>
												</span>
											</div>
											<div class="flex item dates">
												Evento
												<span
													content="Esta é a data prevista para o evento de apresentação dos livros. <br><b>Atenção</b>: A qualquer momento que essa data fique inviável devido impacto nos passos anteriores o Gestor Escolar deve ser comunicado."
													v-tippy="{
														interactive: true,
														arrow: true,
													}"
												>
													<svg
														width="12"
														height="12"
														viewBox="0 0 12 12"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M4.0625 10.6062C4.67083 10.8687 5.31667 11 6 11C6.69167 11 7.34167 10.8687 7.95 10.6062C8.55833 10.3437 9.0875 9.98542 9.5375 9.53125C9.9875 9.07708 10.3437 8.54583 10.6062 7.9375C10.8687 7.32917 11 6.68333 11 6C11 5.30833 10.8687 4.65833 10.6062 4.05C10.3437 3.44167 9.9875 2.9125 9.5375 2.4625C9.0875 2.0125 8.55833 1.65625 7.95 1.39375C7.34167 1.13125 6.69583 1 6.0125 1C5.32083 1 4.67083 1.13125 4.0625 1.39375C3.45417 1.65625 2.92292 2.0125 2.46875 2.4625C2.01458 2.9125 1.65625 3.44167 1.39375 4.05C1.13125 4.65833 1 5.30417 1 5.9875C1 6.67917 1.13125 7.32917 1.39375 7.9375C1.65625 8.54583 2.01458 9.07708 2.46875 9.53125C2.92292 9.98542 3.45417 10.3437 4.0625 10.6062ZM6 9C5.68934 9 5.4375 8.74816 5.4375 8.4375V6.0625C5.4375 5.75184 5.68934 5.5 6 5.5C6.31066 5.5 6.5625 5.75184 6.5625 6.0625V8.4375C6.5625 8.74816 6.31066 9 6 9ZM6 4.5C6.21212 4.5 6.39015 4.43182 6.53409 4.29545C6.67803 4.15909 6.75 3.98485 6.75 3.77273C6.75 3.5606 6.67803 3.37879 6.53409 3.22727C6.39015 3.07576 6.21212 3 6 3C5.78788 3 5.60985 3.07576 5.46591 3.22727C5.32197 3.37879 5.25 3.5606 5.25 3.77273C5.25 3.98485 5.32197 4.15909 5.46591 4.29545C5.60985 4.43182 5.78788 4.5 6 4.5Z"
															fill="#68596E"
														/>
													</svg>
												</span>
											</div>
											<div
												class="flex item tracking-code"
												:class="
													!managerCredential.settings.hide_payment_data
														? 'have-payment'
														: 'no-have-payment'
												"
											>
												Número do pedido
											</div>
										</div>

										<div
											class="va-row lines"
											v-bind:class="{ printError: item.psg_error }"
											v-for="item in list"
											:key="item.id"
										>
											<div class="flex item">
												<div class="title-donation">
													<span class="donation-name">{{ item.name }}</span
													><br />

													<span class="created-data"
														>Início: {{ item.created }}</span
													>

													<div class="va-row">
														<div
															class="flex xs8 created-data no-padding created-data-spacing"
														>
															Turmas:
														</div>

														<div
															class="flex xs4 created-data no-padding text-right created-data-spacing"
														>
															{{ item.grades }}
														</div>

														<div
															class="flex xs8 created-data no-padding created-data-spacing"
														>
															Alunos com livro / Alunos:
														</div>

														<div
															class="flex xs4 created-data no-padding text-right created-data-spacing"
														>
															{{ item.students_complete_count }} /
															{{ item.students_count }}
														</div>

														<div
															class="flex xs10 created-data no-padding created-data-spacing"
														>
															Professor com livro / Professor:
														</div>

														<div
															class="flex xs2 created-data no-padding text-right created-data-spacing"
														>
															{{ item.teacher_complete_count }} /
															{{ item.teacher_count }}
														</div>

														<div
															class="flex xs8 created-data no-padding created-data-spacing"
														>
															Livros à receber por pessoa:
														</div>

														<div
															class="flex xs4 created-data no-padding text-right created-data-spacing"
															v-if="item.books_to_be_made && item.books_count"
														>
															{{ item.books_to_be_made / item.books_count }}
														</div>

														<div
															class="flex xs4 created-data no-padding text-right created-data-spacing"
															v-else
														>
															--
														</div>

														<div
															class="flex xs8 created-data no-padding created-data-spacing"
														>
															Total de livros criados:
														</div>

														<div
															class="flex xs4 created-data no-padding text-right created-data-spacing total-books-info"
														>
															{{
																item.books_completed +
																item.teacher_books_complete_count
															}}
														</div>
													</div>

													<!-- <span class="created-data">
														Turmas: {{ item.grades }}
													</span> -->
												</div>
											</div>

											<!-- <div class="flex item dates book-quantity">
												{{ item.books_completed }}/{{
													item.books_to_be_made ? item.books_to_be_made : 0
												}}<br />
											</div> -->

											<div
												class="flex item dates"
												:class="item.payment_class"
												v-if="!managerCredential.settings.hide_payment_data"
											>
												<div class="va-row">
													<div
														class="flex xs12 no-padding"
														:class="
															item.payment_status === true
																? 'checked-item'
																: 'non-checked'
														"
													>
														<svg
															width="32"
															height="32"
															viewBox="0 0 32 32"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.84459 16.4619L12.0001 20.6174L24.7018 7.92861C25.4821 7.14826 26.7442 7.14826 27.5246 7.92861C28.3049 8.70896 28.3049 9.97107 27.5246 10.7514L13.4179 24.8581C12.6376 25.6384 11.3755 25.6384 10.5951 24.8581L5.02179 19.2847C4.24144 18.5044 4.24144 17.2423 5.02179 16.4619C5.80214 15.6816 7.06425 15.6816 7.84459 16.4619Z"
																fill="#00CF72"
															/>
														</svg>
													</div>
													<div class="flex xs12 item-text">
														<div v-if="item.payment_status === true">
															<del :class="item.payment_delay_class">{{
																dateToFormat(item.payment)
															}}</del>
															<span
																><br />{{
																	dateToFormat(item.payment_realized_day)
																}}</span
															>
														</div>
														<div v-if="item.payment_status === false">
															<span :class="item.payment_delay_class">{{
																dateToFormat(item.payment)
															}}</span>
														</div>
													</div>
												</div>
											</div>
											<div
												class="flex item dates"
												:class="item.teacher_approval_class"
											>
												<div class="va-row">
													<div
														class="flex xs12 no-padding"
														:class="
															item.teacher_approval_status === true
																? 'checked-item'
																: 'non-checked'
														"
													>
														<svg
															width="32"
															height="32"
															viewBox="0 0 32 32"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.84459 16.4619L12.0001 20.6174L24.7018 7.92861C25.4821 7.14826 26.7442 7.14826 27.5246 7.92861C28.3049 8.70896 28.3049 9.97107 27.5246 10.7514L13.4179 24.8581C12.6376 25.6384 11.3755 25.6384 10.5951 24.8581L5.02179 19.2847C4.24144 18.5044 4.24144 17.2423 5.02179 16.4619C5.80214 15.6816 7.06425 15.6816 7.84459 16.4619Z"
																fill="#00CF72"
															/>
														</svg>
													</div>
													<div class="flex xs12 item-text">
														<div v-if="item.teacher_approval_status === true">
															<del :class="item.teacher_approval_delay_class">{{
																dateToFormat(item.teacher_approval)
															}}</del>
															<span
																><br />{{
																	dateToFormat(
																		item.teacher_approval_realized_day
																	)
																}}</span
															>
														</div>
														<div v-if="item.teacher_approval_status === false">
															<span
																:class="item.teacher_approval_delay_class"
																>{{ dateToFormat(item.teacher_approval) }}</span
															>
														</div>
													</div>
												</div>
											</div>
											<div
												class="flex item dates"
												:class="item.production_class"
											>
												<div class="va-row">
													<div
														class="flex xs12 no-padding"
														:class="
															item.production_status === true
																? 'checked-item'
																: 'non-checked'
														"
													>
														<svg
															width="32"
															height="32"
															viewBox="0 0 32 32"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.84459 16.4619L12.0001 20.6174L24.7018 7.92861C25.4821 7.14826 26.7442 7.14826 27.5246 7.92861C28.3049 8.70896 28.3049 9.97107 27.5246 10.7514L13.4179 24.8581C12.6376 25.6384 11.3755 25.6384 10.5951 24.8581L5.02179 19.2847C4.24144 18.5044 4.24144 17.2423 5.02179 16.4619C5.80214 15.6816 7.06425 15.6816 7.84459 16.4619Z"
																fill="#00CF72"
															/>
														</svg>
													</div>
													<div class="flex xs12 item-text">
														<div v-if="item.production_status === true">
															<del :class="item.production_delay_class">{{
																dateToFormat(item.production)
															}}</del>
															<span
																><br />{{
																	dateToFormat(item.production_realized_day)
																}}</span
															>
														</div>
														<div v-if="item.production_status === false">
															<span :class="item.production_delay_class">{{
																dateToFormat(item.production)
															}}</span>
														</div>
													</div>
												</div>
											</div>
											<div class="flex item dates" :class="item.delivery_class">
												<div class="va-row">
													<div
														class="flex xs12 no-padding"
														:class="
															item.delivery_status === true
																? 'checked-item'
																: 'non-checked'
														"
													>
														<svg
															width="32"
															height="32"
															viewBox="0 0 32 32"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.84459 16.4619L12.0001 20.6174L24.7018 7.92861C25.4821 7.14826 26.7442 7.14826 27.5246 7.92861C28.3049 8.70896 28.3049 9.97107 27.5246 10.7514L13.4179 24.8581C12.6376 25.6384 11.3755 25.6384 10.5951 24.8581L5.02179 19.2847C4.24144 18.5044 4.24144 17.2423 5.02179 16.4619C5.80214 15.6816 7.06425 15.6816 7.84459 16.4619Z"
																fill="#00CF72"
															/>
														</svg>
													</div>
													<div class="flex xs12 item-text">
														<div v-if="item.delivery_status === true">
															<del :class="item.delivery_delay_class">{{
																dateToFormat(item.delivery)
															}}</del>
															<span
																><br />{{
																	dateToFormat(item.delivery_realized_day)
																}}</span
															>
														</div>
														<div v-if="item.delivery_status === false">
															<span :class="item.delivery_delay_class">{{
																dateToFormat(item.delivery)
															}}</span>
														</div>
													</div>
												</div>
											</div>
											<div class="flex item dates" :class="item.deadline_class">
												<div class="va-row">
													<div
														class="flex xs12 no-padding"
														:class="
															item.deadline_status === true
																? 'checked-item'
																: 'non-checked'
														"
													>
														<svg
															width="32"
															height="32"
															viewBox="0 0 32 32"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.84459 16.4619L12.0001 20.6174L24.7018 7.92861C25.4821 7.14826 26.7442 7.14826 27.5246 7.92861C28.3049 8.70896 28.3049 9.97107 27.5246 10.7514L13.4179 24.8581C12.6376 25.6384 11.3755 25.6384 10.5951 24.8581L5.02179 19.2847C4.24144 18.5044 4.24144 17.2423 5.02179 16.4619C5.80214 15.6816 7.06425 15.6816 7.84459 16.4619Z"
																fill="#00CF72"
															/>
														</svg>
													</div>
													<div class="flex xs12 item-text">
														{{ dateToFormat(item.deadline) }}
													</div>
												</div>
											</div>

											<div
												class="flex item dates tracking-code"
												:class="
													!managerCredential.settings.hide_payment_data
														? 'have-payment'
														: 'no-have-payment'
												"
											>
												<!-- <a :href="item.tracking_code_url" target="_blank">{{
													item.tracking_code
												}}</a> -->

												<a
													:href="item.tracking_code_url"
													class="btn btn-change"
													v-if="item.tracking_code"
													target="_blank"
												>
													RASTREIO: {{ item.tracking_code }}
												</a>

												<button
													disabled="true"
													class="btn btn-comming-soon"
													:disable="true"
													v-else
												>
													EM BREVE
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- <infinite-loading
				v-if="filterLoaded"
				:identifier="[
					selectStatus,
					selectPayments,
					selectPaymentStatus,
					selectContracts,
				]"
				@infinite="getGradesScroll"
			>
				<div slot="spinner"></div>
				<div slot="no-more"></div>
				<div slot="no-results"></div>
			</infinite-loading> -->
		</div>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";
	import apiErrors from "services/apiErrors";

	const axios = require("axios");

	export default {
		name: "peoples",
		directives: { mask },
		computed: {
			...mapGetters({
				managerCredential: "managerCredential",
				// ...
			}),
		},

		data() {
			return {
				filters: [],

				page: 1,
				list: [],

				emptyPeople: {
					name: "",
					email: "",
					role_id: 20,
					username: "",
					client_id: 1,
					products: null,
					school_id: null,
					grade_id: [],
				},

				nameSchool: "",

				listStatus: [],
				listPayments: [],
				listPaymentStatus: [],
				listContracts: [],

				filterLoaded: false,

				listSchool: [],

				selectSchool: "",
				selectStatus: "",
				selectPayments: "",
				selectPaymentStatus: "",
				selectContracts: "",
				bookLimit: "",
			};
		},

		mounted() {
			//self.getGrades();
			this.getGradesScroll();

			this.getFilters();

			this.fixBookLimit();
		},

		methods: {
			fixBookLimit() {
				const self = this;
				if (self.managerCredential.settings.book_limit !== undefined && self.managerCredential.settings.book_limit != null) {
					self.bookLimit = managerCredential.settings.book_limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
				}
				
			},
			cleanFilters() {
				const self = this;

				self.selectSchool = "";
				self.selectStatus = "";
				self.selectPayments = "";
				self.selectPaymentStatus = "";
				self.selectContracts = "";
			},

			dateToFormat(date) {
				var year = date.substring(8, 10);
				var parteDate = date.substring(0, 6);
				return parteDate + year;
			},

			getFilters() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				axios
					.get(process.env.VUE_APP_ROOT_API + "/projects/filters", config)
					.then((response) => {
						self.filters = response.data.data;

						self.listStatus = response.data.data.status.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						// self.listPayments = response.data.data.payments.map((s) => ({
						// 	key: s.id,
						// 	value: s.name,
						// }));

						self.listPaymentStatus = response.data.data.payment_status.map(
							(s) => ({
								key: s.id,
								value: s.name,
							})
						);

						self.listContracts = response.data.data.contracts.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						self.filterLoaded = true;
					})
					.catch((e) => {
						// this.errors.push(e)
						apiErrors.handle(e);
					});
			},

			onChangeFilterStatus(event) {
				this.page = 1;
				this.list = [];
				this.selectStatus = event.target.value;
				this.getGradesScroll();
			},

			onChangeFilterSchools(event) {
				this.page = 1;
				this.list = [];
				this.selectSchool = event.target.value;
				this.getGradesScroll();
			},

			onChangeFilterPaymentStatus(event) {
				this.page = 1;
				this.list = [];
				this.selectPaymentStatus = event.target.value;
				this.getGradesScroll();
			},

			onChangeFilterContracts(event) {
				this.page = 1;
				this.list = [];
				this.selectContracts = event.target.value;
				this.getGradesScroll();
			},

			getGradesScroll($state) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				//self.list = [];

				let url =
					"/dash/projects?page=" +
					self.page +
					"&status=" +
					self.selectStatus +
					"&payment_status=" +
					self.selectPaymentStatus +
					"&contracts=" +
					self.selectContracts;

				axios
					.get(process.env.VUE_APP_ROOT_API + url, config)
					.then((response) => {
						if (response.data.data.projects.length) {
							response.data.data.projects.forEach((element) => {
								if (
									self.selectSchool &&
									parseInt(self.selectSchool) === parseInt(element.id)
								) {
									self.list.push(element);
								}

								if (!self.selectSchool) {
									self.list.push(element);
								}

								//if (list.length === 0) {
								var school = {
									key: element.id,
									value: element.name,
								};
								self.listSchool.push(school);
								//}
							});
							this.page += 1;
							$state.loaded();
						} else {
							//$state.complete();
						}
					})
					.catch((e) => {
						// this.errors.push(e)
						apiErrors.handle(e);
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.title-donation {
		// padding: 10px 10px;
		padding-bottom: 0px;

		.donation-name {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			text-transform: capitalize;
			color: #68596e;
		}
	}

	.books-count {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		text-decoration-line: underline;
		color: #afa2ba;
		display: flex;
		justify-content: right;
		align-items: center;
	}

	.created-data {
		font-size: 14px;
		display: block;
		color: #afa2ba;
	}

	.created-data-spacing {
		margin-top: 14px;
	}

	.sets-list {
		font-size: 12px;
		padding: 24px;
		padding-bottom: 0px;

		.lines {
			width: 100%;

			border-bottom: solid 1px #dfdae4;

			&:last-child {
				border-bottom: none;
			}

			.item {
				border-right: solid 1px #dfdae4;
				width: 30%;
				display: flex;
				align-items: center;

				&.dates {
					display: -ms-flexbox;
					display: -webkit-flex;
					display: flex;
					width: 10%;

					-ms-flex-align: center;
					-webkit-align-items: center;
					-webkit-box-align: center;

					align-items: center;
					text-align: center;
					justify-content: center;

					.item-text {
						padding-top: 0px !important;
					}

					i {
						font-size: 25px;
					}

					.non-checked {
						color: rgba(0, 0, 0, 0.1);
						svg {
							path {
								fill: rgba(0, 0, 0, 0.1);
							}
						}
					}
					.checked-item {
						i {
							color: #19dea3;
						}
						svg {
							path {
								fill: #19dea3;
							}
						}
					}
				}

				&.book-quantity {
					width: 12%;
				}

				&.tracking-code {
					border-right: none !important;
					text-align: center;
					justify-content: center;
					width: 30%;

					&.have-payment {
						width: 20%;
					}

					button {
						border: 1px solid #4965bc;
						background-color: #fff;
						color: #4965bc;
					}

					a {
						text-align: center;
						justify-content: center;

						border: 1px solid #4965bc;
						background-color: #fff;
						color: #4965bc;

						&:hover {
							text-decoration: none;
						}
					}
				}
			}
		}

		.header {
			background-color: #f8f7fc;
			border-bottom: solid 1px #dfdae4;
			color: #68596e;
			height: 61px;
			line-height: 45px;
			padding: 0px 20px;
			font-size: 12px;

			span {
				font-weight: normal;
				cursor: pointer;
			}
		}

		::v-deep .select {
			font-size: 14px !important;
		}

		.full-line {
			margin: 0 auto;
			margin-bottom: 5px;
		}

		.flex {
			&.success {
				color: #68596e;
			}

			&.danger {
				background-color: #fff0f0;
			}
		}
	}

	.table-list {
		border: solid 1px #dfdae4;
		border-radius: 15px;
		overflow: hidden;
		margin-top: 15px !important;
		margin: 0px 24px 24px 24px;
		padding: 0;
		width: 100%;

		.header {
			.item {
				width: 30%;
				font-weight: 900;
				font-size: 11px !important;
				// padding-left: 0px;
				// padding-right: 0px;
				text-align: center;
				display: block;

				&.main {
					padding-left: 8px;
					text-align: left;
				}
				&.book-quantity {
					width: 12%;
				}

				&.dates {
					width: 10%;
				}

				&.dates {
					width: 10%;
				}

				&.tracking-code {
					width: 30%;

					&.have-payment {
						width: 20%;
					}
				}
			}
		}

		@media (max-width: 768px) {
			width: 1024px;
			max-width: 1024px;
		}
		// .header{
		//   @media(max-width: 768px){
		//   display: none;
		// }
		// }

		.text-center {
			margin: 0px 0px 3px 2px;
		}
	}

	.schedule-list {
		text-align: center;

		.bullet {
			display: flex;
			width: 110px;
			height: 110px;
			border-radius: 60px;
			margin: 0 auto;
			text-align: center;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;

			&.blue {
				background-color: rgb(74, 144, 226);
				color: #fff;
			}

			&.purple {
				background-color: rgb(116, 106, 208);
				color: #fff;
			}

			&.purple-green {
				background-color: rgb(0, 207, 113);
				color: #fff;
			}
		}

		.blocks {
			padding: 10px 15px;
			margin-bottom: 15px;

			&.blue {
				background-color: rgb(74, 144, 226);
				color: #fff;
			}

			&.purple {
				background-color: rgb(116, 106, 208);
				color: #fff;
			}

			&.purple-green {
				background-color: rgb(0, 207, 113);
				color: #fff;
			}
		}
	}
	.btn-login {
		background-color: transparent;
		border: 0px;
		color: #4965bc;
	}
	.hide {
		display: none;
	}
	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	.fiscal-notes ::v-deep .form-group:last-child {
		margin-bottom: 10px !important;
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #68596e;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #68596e;
			margin-top: 34px;
			margin-bottom: 34px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	// Default of page

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #68596e;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #68596e;
			margin-top: 34px;
			margin-bottom: 34px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #68596e;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		border-radius: 16px;
		background-color: #ffffff;
		position: relative;
		box-shadow: 0px 4px 16px #dfdae47a;

		h4 {
			font-size: 16px;
			color: #68596e;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 0px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}

			.btn-change-edit {
				border: none;
				color: #4965bc;
				background: transparent;
			}

			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #68596e;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #68596e;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #68596e;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #68596e;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #68596e;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}

	// label{
	//   color:#68596e;
	// }

	.printError {
		color: red !important;
	}

	.yellow {
		color: rgb(222, 187, 14) !important;
	}

	.red {
		color: red !important;
	}

	.voucher-disclaimer {
		/* paragraph-primary/lg */
		padding: 33px 31px;
		font-family: "Roboto";
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		/* or 150% */

		/* color / neutral / darkest */

		color: #68596e;
		border-bottom: 1px solid #dfdae4;
	}

	.empty-area {
		padding: 24px;

		.empty-box {
			width: 100%;
			border: 1px solid #dfdae4;
			border-radius: 16px;
			overflow: hidden;

			&-header {
				padding: 22px;
				background: #f4f2fb;
				border-bottom: 1px solid #dfdae4;

				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 12px;
				line-height: 14px;
				/* identical to box height */

				display: flex;
				align-items: center;
				text-transform: uppercase;

				color: #68596e;
			}

			&-body {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 21px;
				text-align: center;
				color: #68596e;
				padding: 24px;
			}

			.btn {
				margin-top: 15px;
			}
		}
	}

	.btn-comming-soon {
		border: 1px solid #dfdae4 !important;
		background-color: #fff;
		color: #dfdae4 !important;
	}

	.total-books-info {
		font-size: 16px;
		color: #68596e;
	}
</style>
